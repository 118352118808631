<template>
  <div>
    <button
      :class="btnClass"
      :disabled="btnDisabled"
      ref="el"
      type="button"
      data-bs-toggle="dropdown"
      aria-expanded="false"
    >
      <span v-if="btnText">{{ btnText }}</span>
      <fa-icon v-else :icon="['fas', btnIcon]"></fa-icon>
    </button>
    <ul :class="btnDropdownDir" class="dropdown-menu">
      <slot></slot>
    </ul>
  </div>
</template>

<script>
import { Dropdown } from 'bootstrap';

export default {
  name: 'BootstrapDropdown',
  props: {
    btnClass: {
      type: String,
      default: 'btn btn-circle',
    },
    btnIcon: {
      type: String,
      default: 'ellipsis-h',
    },
    btnText: {
      type: String,
      default: null,
    },
    btnDisabled: {
      type: Boolean,
      default: false,
    },
    btnDropdownDir: {
      type: String,
      default: 'dropdown-menu-end',
    },
  },
  data() {
    return {
      dropdown: null,
    };
  },
  mounted() {
    const { el } = this.$refs;

    this.dropdown = new Dropdown(el, {
      autoClose: true,
    });

    ['show', 'shown', 'hide', 'hidden'].forEach((e) => {
      el.addEventListener(`${e}.bs.dropdown`, () => {
        this.$emit(e);
      });
    });
  },
};
</script>
